import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'
var pvhApiBaseSupplier = "http://localhost:5202/";

export const supplierList = (code, supplierName, supplierGroup, page, pageSize) => {
    return request({
        url: pvhApiBaseSupplier+"supplier/Supplier/GetSupplierList",
        method: "get",
        params: {
            "code": code,
            "supplierName": supplierName,
            "supplierGroup": supplierGroup,
            "page": page,
            "pageSzie": pageSize
        }
    })
}
export const addInfo = (row) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/PostSupplierInfo",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const editInfo = (row) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/PutSupplierInfo",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delInfo = (id) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/DeleteSupplierInfo",
        method: "delete",
        params: {
                id
        }
    })
}
export const settlementMethodDDL = (type) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/GetSettlementMethodDic",
        method: "get",
        params: {
             type
        }
    })

}
export const editStatus = (row) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/UpdateCertifiedStatus",
        method: "put",
        data: {
            BrandBusinessId:"",
            CertifiedId: row.id,
            Status: row.Status == 1 ? false : true
        }
    })
}
export const codeIsExist = (code) => {
    return request({
        url: pvhApiBaseSupplier + "supplier/Supplier/GetSupplierByCode",
        method: "get",
        params: {
            code
        }
    })
}

export const SupplierList = (row) => {
    return request({
        url: pvhApiBaseSupplier +"supplier/Supplier/GetSupplierByCompany",
        method: "get",
        params: {
            ...row
        }
    })
}